/* Progress Bar */
.progress-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.progress-bar {
  width: 400px;
  height: 15px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
  border: 1px solid #2959f8;
}

.progress-fill {
  height: 100%;
  background: #2959f8;
  transition: width 1s linear;
}
