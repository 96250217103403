.product_status {
  span {
    font-size: 11px;
    line-height: 20px;
    text-transform: uppercase;
  }
  &.danger {
    span {
      color: #fd397a;
    }
  }
  &.warning {
    span {
      color: #ffb822;
    }
  }
}
