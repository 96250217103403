.product-prices-submit {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  .check-group {
    margin: 0;
    label {
      margin: 0;
    }
  }
}

.product_prices_table {
  margin-left: -20px;
  margin-right: -20px;
  width: calc(100% + 40px);
  .table-head {
    th {
      padding: 10px;
      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
    }
  }
  .table-body {
    td {
      padding: 10px;
      &:first-child {
        padding-left: 20px;
      }
      &:last-child {
        padding-right: 20px;
      }
      .offers-group{
        display: flex;
        flex-flow: wrap;
        gap: 10px;
        a{
          display: inline-block;
          svg{
            width: 20px;
          }
        }
      }
    }
  }
}
